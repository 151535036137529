<template>
    <!-- Confirmation Modal -->
    <div>

        <div
            id="confirmation"
            class="modal fade confirmation"
            tabindex="-1"
            role="dialog"
            aria-labelledby="confirmationLabel"
            aria-hidden="true">
            <div
                class="modal-dialog"
                role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ getModalTitle }}</h5>
                        <button
                            type="button"
                            class="close"
                            :class="currentLocale === 'ur' ? 'ml-20-neg' : ''"
                            data-dismiss="modal"
                            aria-label="Close">
                            <i class="pratilipi-icons">&#xe801;</i>
                        </button>
                    </div>
                     <div class="modal-body" v-if="getModalData && getModalData.delete">
                        <form>
                            <div class="form-group">
                                <label>{{ getModalMessage }}</label>
                            </div>
                            <div class = "text-right">
                            <button
                                type="button"
                                class="btn btn-light"
                                @click = "sendCancelEvent"
                                data-dismiss="modal"
                                aria-label="Close">__("cancel")</button>
                            <button
                                type="button"
                                class="btn btn-light"
                                @click="dispatchActionAndCloseModal">__("pratilipi_confirm_delete_content_okay")</button>
                            </div>
                        </form>
                    </div>
                    <div class="modal-body" v-else>
                        <form>
                            <div class="form-group">
                                <label>{{ getModalMessage }}</label>
                            </div>
                            <button
                                type="button"
                                class="btn btn-submit"
                                data-dismiss="modal"
                                aria-label="Close">__("cancel")</button>
                            <button
                                type="button"
                                class="cancel"
                                @click="dispatchActionAndCloseModal">__("pratilipi_confirm_delete_content_okay")</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="confirmation-primary"
            class="modal fade confirmation"
            tabindex="-1"
            role="dialog"
            aria-labelledby="confirmationLabel"
            aria-hidden="true">
            <div
                class="modal-dialog"
                role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ getModalTitle }}</h5>
                        <button
                            type="button"
                            class="close"
                            :class="currentLocale === 'ur' ? 'ml-20-neg' : ''"
                            data-dismiss="modal"
                            aria-label="Close">
                            <i class="pratilipi-icons">&#xe801;</i>
                        </button>
                    </div>
                   
                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <label>{{ getModalMessage }}</label>
                            </div>
                            <button
                                type="button"
                                class="btn btn-submit"
                                @click="dispatchActionAndCloseModal">__("pratilipi_confirm_delete_content_okay")</button>
                            <button
                                type="button"
                                class="cancel"
                                data-dismiss="modal"
                                aria-label="Close">__("cancel")</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import mixins from '@/mixins';
    
    
    export default {
        mixins: [
            mixins
        ],
        data () {
            return {
                currentLocale: '',
            };
        },
        computed: {
            ...mapGetters([
                'getModalTitle',
                'getModalActionAndData',
                'getModalMessage',
                 'getModalData'
            ])
        },
        methods: {
            ...mapActions([
                'dispatchAction',
                'updatePrefilledValue'
            ]),
            dispatchActionAndCloseModal () {
                if( this.getModalData.delete && !this.getModalData.series ) {
                    this.triggerAnanlyticsEvent(`DELETEDRAFTDIALOGUE_WRITER_EDITOR`, 'CONTROL', {'CONTENT_ID':this.getModalData.pratilipiId,'TYPE': 'YES' });    
                } else if( this.getModalData.delete && this.getModalData.series ) {
                    this.triggerAnanlyticsEvent(`REMOVEPRATILIPI_SERIESM_SERIES`, 'CONTROL', {'SERIES_ID': this.getModalData.seriesId, 'PRATILIPI_ID': this.getModalData.pratilipiId, 'TYPE': 'YES' });    
                }
                $('#confirmation').modal('hide');
                $('#confirmation-primary').modal('hide');
                this.dispatchAction();
            },
            sendCancelEvent () {
                if( this.getModalData.delete && !this.getModalData.series ) {
                    this.triggerAnanlyticsEvent(`DELETEDRAFTDIALOGUE_WRITER_EDITOR`, 'CONTROL', {'CONTENT_ID':this.getModalData.pratilipiId,'TYPE': 'NO' }); 
                } else if( this.getModalData.delete && this.getModalData.series ) {
                    this.triggerAnanlyticsEvent(`REMOVEPRATILIPI_SERIESM_SERIES`, 'CONTROL', {'SERIES_ID': this.getModalData.seriesId, 'PRATILIPI_ID': this.getModalData.pratilipiId, 'TYPE': 'YES' });    
                }
            }
        },
        created() {
            this.currentLocale = this.getLanguageCode(process.env.LANGUAGE);
        }
    };
</script>

<style lang="scss" scoped>
.confirmation {
    text-align: left;
    max-width: 350px;
    margin: 50px auto;
    .modal-body {
        padding-top: 0;
    }
    .modal-header {
        padding-bottom: 10px;
    }
    .form-group {
        font-size: 14px;
    }
    .btn-submit {
        background: #d0021b;
        color: #fff;
        border: 0;
        font-size: 14px;
        float: right;
    }
    .cancel {
        background: #e9e9e9;
        border: 0;
        float: right;
        font-size: 12px;
        line-height: 33px;
        margin-right: 10px;
        padding: 0 10px;
        border-radius: 3px;
    }
}
.ml-20-neg {
    margin-left: -20px !important;
}
</style>